<template>
	<div>
		<Header :title='title'></Header>
		<div class="listWrap">
			<div @click="goDetail(item,index)" class="listChild" v-for="(item,index) in list" :key='index'>
				<div class="imgBox">
					<img v-if="type==2" :src="host+item.logo" style="width: 100%;height: 100%;">
					<img v-else :src="host+item.thumb" style="width: 100%;height: 100%;">
				</div>
				<div class="shName">{{item.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		brandList,
		fieldList
	} from '../../api/home.js'
	import Header from '@/components/header.vue'
	import {
		host
	} from '../../utils/host.js'
	export default {
		name: "brandRecommend",
		data() {
			return {
				host: host,
				list: [],
				title: '',
				type: '', //1是雪场  2是品牌
			}
		},
		components: {
			Header
		},
		created() {
			this.type = this.$route.query.type
			if (this.type == 1) {
				this.title = '雪场推荐'
			} else if (this.type == 2) {
				this.title = '品牌推荐'
			}
			this.getList()
		},
		methods: {
			getList() {
				if (this.type == 2) {
					brandList().then(res => {
						console.log('品牌列表', res)
						this.list = res.data
					})
				} else {
					fieldList().then(res => {
						console.log('雪场列表', res)
						this.list = res.data
					})
				}
			},
			goDetail(item, index) {
				console.log(item)
				this.$router.push({
					path: '/brandDetail',
					query: {
						type: this.type,
						id:item.id
					}
				})
			}
		}
	}
</script>

<style scoped>
	.listWrap {
		margin: 7px 16px 0 11px;
		display: flex;
		flex-flow: row wrap;
	}

	.listChild {
		margin: 8px 0 0 8px;
		text-align: center;
	}

	.imgBox {
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		width: 2.88rem;
		height: 2.88rem;
	}

	.shName {
		width: 2.88rem;
		color: #454646;
		font-size: 14px;
		font-weight: bold;
		margin-top: 7px;
	}
</style>
